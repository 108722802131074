.fish {
    position: absolute;
    width: 100%;
    height: 100%;
}
.fish-1 {
    width: 346px;
    position: absolute;
    top: 10%;
    left: 25%;
    transform: translate(-10%, -25%);
}
.fish-2 {
    width: 332px;
    position: absolute;
    top: 80%;
    left: 15%;
    transform: translate(-80%, -15%);
}
.fish-3 {
    width: 221px;
    position: absolute;
    top: 50%;
    left: 85%;
    transform: translate(-50%, -85%);
}
.menu {
    position: relative;
}
