.shrink {
    padding: 30px 0;
    transition: 0.2s!important;
}
.no-shrink {
    padding: 0;
    transition: 0.4s!important;
}

a {
    text-decoration: none;
}
